<ng-container *transloco="let t">
  <div class="actions-menu-container">
    <p-menu
      #menu
      [model]="items"
      [popup]="true"
      [appendTo]="'body'"
      [attr.aria-label]="t('eventActionsMenu')">
      <ng-template pTemplate="item" let-item>
        <div
          class="p-menuitem-content"
          data-pc-section="content"
          tabindex="0"
          (click)="onItemClick(item)"
          (keydown.enter)="onItemClick(item)"
          (keydown.space)="onItemClick(item)">
          <a
            pRipple
            inert
            class="p-ripple p-element p-menuitem-link"
            target="undefined"
            tabindex="-1"
            data-pc-section="action">
            <span class="p-menuitem-icon pi" [class]="item.icon"></span>
            <span class="p-menuitem-text ml-2">{{ t(item.i18nKey) }}</span>
          </a>
        </div>
      </ng-template>
    </p-menu>
    <p-button
      icon="pi pi-ellipsis-h"
      role="button"
      aria-haspopup="true"
      aria-controls="menu"
      [link]="true"
      [attr.aria-label]="t('openEventActionsMenu')"
      (onClick)="menu.toggle($event)">
      <span class="sr-only open-event-actions">{{ t('openEventActionsMenu') }}</span>
    </p-button>
  </div>
</ng-container>
