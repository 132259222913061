<ng-container *transloco="let t">
  <div class="language-switcher" role="radiogroup" aria-labelledby="language-switcher-label">
    <span id="language-switcher-label" class="sr-only">{{ t('languageSwitcher') }}</span>
    @if(languages.length > 2) {
      <p-dropdown
        [options]="languages"
        [(ngModel)]="selectedLanguage.language"
        optionValue="language"
        optionLabel="language"
        (ngModelChange)="selectLanguage(selectedLanguage.language)">
      </p-dropdown>
    }
    @else{
      @for (language of languages; track language.language; let i = $index) {
      <button
        type="button"
        class="language-switcher__button"
        [class.language-switcher--selected]="language.isSelected"
        (click)="selectLanguage(language.language)"
        role="radio"
        [attr.aria-checked]="language.isSelected"
        [attr.aria-label]="language.language"
      >
        {{ language.language }}
      </button>
      @if (i < languages.length - 1) {
        <span> | </span>
      }
    }
    }
  </div>
</ng-container>

