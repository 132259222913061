import { TimeRange } from '../../models';

const getMonthCurrent = () => new Date().getUTCMonth();

const getYearCurrent = () => new Date().getUTCFullYear();

export const getTimeRange = (range: TimeRange) => {
  switch (range) {
    case TimeRange.MonthPrevious:
      return [
        new Date(new Date().setUTCMonth(getMonthCurrent() - 1, 1)),
        new Date(new Date().setUTCMonth(getMonthCurrent(), 0)),
      ];
    case TimeRange.MonthCurrent:
      return [
        new Date(new Date().setUTCMonth(getMonthCurrent(), 1)),
        new Date(new Date().setUTCMonth(getMonthCurrent() + 1, 0)),
      ];
    case TimeRange.MonthNext:
      return [
        new Date(new Date().setUTCMonth(getMonthCurrent() + 1, 1)),
        new Date(new Date().setUTCMonth(getMonthCurrent() + 2, 0)),
      ];
    case TimeRange.YearPrevious:
      return [
        new Date(new Date().setUTCFullYear(getYearCurrent() - 1, 0, 1)),
        new Date(new Date().setUTCFullYear(getYearCurrent(), 0, 0)),
      ];
    case TimeRange.YearCurrent:
      return [
        new Date(new Date().setUTCFullYear(getYearCurrent(), 0, 1)),
        new Date(new Date().setUTCFullYear(getYearCurrent() + 1, 0, 0)),
      ];
    case TimeRange.YearNext:
      return [
        new Date(new Date().setUTCFullYear(getYearCurrent() + 1, 0, 1)),
        new Date(new Date().setUTCFullYear(getYearCurrent() + 2, 0, 0)),
      ];
    default:
      return [];
  }
};
