<div class="event-action" *transloco="let t">
  @if (displayConfirmButton) {
    <div class="event-action__confirm-button">
      <button
        pButton
        class="p-button-primary"
        icon="pi pi-check"
        [label]="t('confirm')"
        [attr.aria-label]="t('confirm')"
        (click)="onConfirmClick()"></button>
    </div>
  }
  <div class="event-action__actions-menu">
    <shared-actions-menu
      [items]="eventActions"
      (selectedItem)="onActionClick($event)"></shared-actions-menu>
  </div>
</div>
