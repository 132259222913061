import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TreeNode } from 'primeng/api';
import { TreeSelectModule } from 'primeng/treeselect';

@Component({
  selector: 'shared-tree-select',
  standalone: true,
  imports: [CommonModule, TreeSelectModule, FormsModule],
  templateUrl: './tree-select.component.html',
  styleUrl: './tree-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeSelectComponent {
  public options = input.required<TreeNode[]>();
  public placeholder = input<string>();
  public hasSearch = input<boolean>(true);

  public changeEvent = output<string[]>();

  public selectedOptions: TreeNode<string>[] = [];

  onChange(): void {
    const selectedKeys = this.selectedOptions.map((option) => option.key || '');
    this.changeEvent.emit(selectedKeys);
  }
}
