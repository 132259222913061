import { MockedResponse } from '../model';

interface ProfileDto {
  information: ProfileInformationDto;
  settings: ProfileSettingsDto;
}

export interface ProfileInformationDto {
  name: string;
  displayName: string;
  location: string;
  email: string;
  phone: string;
}

export interface ProfileSettingsDto {
  communicationLanguage: string;
  jobTitle: string;
  languages: string[];
}

export const PROFILE_DATA: MockedResponse<ProfileDto> = {
  data: [
    {
      information: {
        name: 'Kianna Ekstrom Bothman',
        displayName: 'Kianna',
        location: 'Norway',
        email: 'kianna.ekstrom.bothman@opentech.com',
        phone: '+47 34 2746 4294',
      },
      settings: {
        communicationLanguage: 'English',
        jobTitle: 'Certification manager',
        languages: ['English', 'Italian'],
      },
    },
  ],
  isSuccess: true,
  message: '',
  errorCode: '',
};
