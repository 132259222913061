import { delay, Observable, of } from 'rxjs';

import {
  CONTRACT_LIST,
  EMPTY_RESPONSE,
  INVOICE_LIST,
  PROFILE_DATA,
  SCHEDULES_LIST,
} from './data';
import { MockedRepositoriesType, MockedResponse } from './model';

export class MockRepositoryService {
  private static readonly repositoriesMap: Map<string, any> = new Map<
    MockedRepositoriesType,
    any
  >([
    [MockedRepositoriesType.InvoiceList, INVOICE_LIST],
    [MockedRepositoriesType.ContractList, CONTRACT_LIST],
    [MockedRepositoriesType.SchedulesList, SCHEDULES_LIST],
    [MockedRepositoriesType.ProfileData, PROFILE_DATA],
  ]);

  static getAll<T>(
    clientName: string,
    delayInMs = 0,
  ): Observable<MockedResponse<T>> {
    const response = this.getResponse<T>(clientName);

    return of(response).pipe(delay(delayInMs));
  }

  static getById<T>(
    clientName: string,
    idField: string,
    idValue: string | number,
    delayInMs = 0,
  ): Observable<T | null> {
    const response = this.getResponse<T>(clientName);
    const item = response.data.find((d: any) => d[idField] === idValue) || null;

    return of(item).pipe(delay(delayInMs));
  }

  static getByIds<T extends Record<string, any>>(
    clientName: string,
    idField: keyof T,
    idValues: Array<string | number>,
    delayInMs = 0,
  ): Observable<T[]> {
    const response = this.getResponse<T>(clientName);

    const items = response.data.filter((d: T) => {
      const fieldValue = d[idField] as string | number;

      return idValues.includes(fieldValue);
    });

    return of(items).pipe(delay(delayInMs));
  }

  private static getResponse<T>(clientName: string): MockedResponse<T> {
    return this.repositoriesMap.get(clientName) ?? EMPTY_RESPONSE;
  }
}
