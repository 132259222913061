import { Chart, LegendItem } from 'chart.js';

import { ChartTypeEnum } from '../../constants';

const getOrCreateLegendList = (_chart: Chart, id: string): HTMLUListElement => {
  const legendContainer = document.getElementById(id);
  let listContainer = legendContainer?.querySelector('ul') as HTMLUListElement;

  if (!listContainer) {
    listContainer = document.createElement('ul');
    Object.assign(listContainer.style, {
      display: 'flex',
      flexDirection: 'row',
      listStyleType: 'none',
      padding: '0',
    });
    legendContainer?.appendChild(listContainer);
  }

  return listContainer;
};

const toggleVisibility = (chart: Chart, item: any) => {
  const chartConfig = chart.config as any;
  const { index, datasetIndex } = item;

  if ([ChartTypeEnum.Pie, ChartTypeEnum.Doughnut].includes(chartConfig.type)) {
    chart.toggleDataVisibility(index);
  } else {
    const isVisible = chart.isDatasetVisible(datasetIndex);
    chart.setDatasetVisibility(datasetIndex, !isVisible);
  }
};

const createLegendItem = (item: LegendItem, chart: Chart): HTMLLIElement => {
  if (item.datasetIndex === undefined) return document.createElement('li');

  const { datasetIndex } = item;
  const isVisible = chart.isDatasetVisible(datasetIndex);

  const li = document.createElement('li');
  Object.assign(li.style, {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: '10px',
    padding: '4px 10px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '10px',
    borderColor: isVisible ? '#99D9F0' : '#CBCAC8',
    backgroundColor: isVisible ? '#FFFFFF' : '#F3F2F2',
  });

  li.onclick = () => {
    toggleVisibility(chart, item);
    chart.update();
  };

  const boxSpan = document.createElement('span');
  Object.assign(boxSpan.style, {
    backgroundColor: isVisible ? item.fillStyle : '#F3F2F2',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: isVisible ? item.fillStyle : '#696763',
    display: 'inline-block',
    flexShrink: '0',
    height: '8px',
    marginRight: '10px',
    width: '8px',
    opacity: isVisible ? '1' : '0.5',
    borderRadius: '50%',
  });

  const fontColor = String(item.fontColor) || '#000000';

  const textContainer = document.createElement('p');
  Object.assign(textContainer.style, {
    color: isVisible ? fontColor : '#696763',
    userSelect: 'none',
  });

  textContainer.textContent = item.text;

  li.append(boxSpan, textContainer);

  return li;
};

export const createHtmlLegendPlugin = (id: string) => ({
  id,
  afterUpdate(chart: any) {
    const ul = getOrCreateLegendList(chart, id);

    // Clear existing legend items
    ul.innerHTML = '';

    // Generate and append new legend items
    const items =
      chart.options.plugins?.legend?.labels?.generateLabels(chart) || [];
    items.forEach((item: LegendItem) => {
      const legendItem = createLegendItem(item, chart);
      ul.appendChild(legendItem);
    });
  },
});
