<ng-container *transloco="let t">
  <p-multiSelect
    role="listbox"
    [attr.aria-label]="t('multiSelectDropdown')"
    [ngModel]="selected()"
    [filterPlaceHolder]="t('search')"
    [filter]="hasSearch()"
    [options]="options()"
    [placeholder]="placeholder()"
    [scrollHeight]="scrollHeight"
    [showToggleAll]="false"
    [ariaLabel]="t('multiselectOptions')"
    (onChange)="onChange($event)">

    <ng-template pTemplate="header">
      <p-triStateCheckbox
        role="checkbox"
        [attr.aria-label]="t('selectAllOptions')"
        [ngModel]="triState()"
        [checkboxFalseIcon]="'pi pi-minus'"
        [label]="t('all')"
        (onChange)="onChangeTriState($event)">
      </p-triStateCheckbox>
    </ng-template>

  </p-multiSelect>
</ng-container>