import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  output,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { MultiSelectChangeEvent, MultiSelectModule } from 'primeng/multiselect';
import {
  TriStateCheckboxChangeEvent,
  TriStateCheckboxModule,
} from 'primeng/tristatecheckbox';

import {
  SHARED_SELECT_MULTIPLE_SCROLL_HEIGHT_PX,
  SHARED_SELECT_MULTIPLE_SEARCH_LIMIT,
} from './select-multiple.constants';

export interface SharedSelectMultipleDatum<T> {
  label: string;
  value: T;
}

@Component({
  selector: 'shared-select-multiple',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslocoDirective,
    MultiSelectModule,
    TriStateCheckboxModule,
  ],
  templateUrl: './select-multiple.component.html',
  styleUrl: './select-multiple.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedSelectMultipleComponent<T> {
  public options = input.required<SharedSelectMultipleDatum<T>[]>();
  public prefill = input<SharedSelectMultipleDatum<T>[]>([]);
  public placeholder = input<string>();

  public changeEvent = output<T[]>();

  public hasSearch = computed(
    () => this.options().length > SHARED_SELECT_MULTIPLE_SEARCH_LIMIT,
  );
  public scrollHeight = `${SHARED_SELECT_MULTIPLE_SCROLL_HEIGHT_PX}px`;
  public selected = signal<T[]>([]);
  public triState = computed(() =>
    this.selected().length !== 0
      ? this.options().length === this.selected().length
      : null,
  );

  constructor() {
    effect(
      () => {
        this.selected.set(this.prefill().map((p) => p.value));
      },
      { allowSignalWrites: true },
    );
  }

  onChange(event: MultiSelectChangeEvent): void {
    this.selected.set(event.value);
    this.changeEvent.emit(this.selected());
  }

  onChangeTriState(event: TriStateCheckboxChangeEvent): void {
    event.originalEvent.stopImmediatePropagation();
    this.selected.set(this.getSelectedByTriState(event.value));
    this.changeEvent.emit(this.selected());
  }

  private getSelectedByTriState(value: boolean | null): T[] {
    return value ? this.options().map((o) => o.value) : [];
  }
}
