import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ChartData, ChartEvent, ChartType, Plugin } from 'chart.js';
import { Chart } from 'chart.js/auto';

import { ChartTypeEnum } from '../../constants';
import { createHtmlLegendPlugin } from '../../helpers';

let chartInstanceId = 0;

@Component({
  selector: 'shared-chart',
  standalone: true,
  templateUrl: './chart.component.html',
  styleUrl: './chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent implements OnDestroy {
  private chart!: Chart;
  private _data!: ChartData | null;
  private dataIsLoaded = false;

  @Input()
  set data(value: ChartData | null) {
    this._data = value;

    if (!this.dataIsLoaded && value) {
      this.initChart();
      this.dataIsLoaded = true;
    } else if (this.dataIsLoaded) {
      this.updateChart();
    }
  }

  @Input() options!: any;
  @Input() type: ChartType = ChartTypeEnum.Bar;
  @Input() plugins: Plugin[] = [];
  @Input() isCustomLegendVisible = false;
  @Output() chartClick = new EventEmitter<any>();

  @ViewChild('chartCanvas', { static: false })
  chartCanvas!: ElementRef<HTMLCanvasElement>;

  // eslint-disable-next-line no-plusplus
  legendId = `legend-container-${chartInstanceId++}`;

  ngOnDestroy(): void {
    this.destroyChart();
  }

  private initChart(): void {
    if (this.chart) this.chart.destroy();

    const plugins =
      this.type !== ChartTypeEnum.Doughnut
        ? [...this.plugins, createHtmlLegendPlugin(this.legendId)]
        : [...this.plugins];

    const chartConfig = {
      type: this.type,
      data: this._data!,
      options: {
        ...this.options,
        responsive: true,
        onClick: (_: ChartEvent, elements: Array<any>) => {
          if (elements.length > 0) {
            this.chartClick.emit(elements[0]);
          }
        },
        plugins: {
          ...this.options.plugins,
          htmlLegend: {
            containerID: this.legendId,
          },
        },
      },
      plugins,
    };

    this.chart = new Chart(this.chartCanvas.nativeElement, chartConfig);
  }

  private updateChart(): void {
    this.chart.data = this._data!;
    this.chart.update();
  }

  private destroyChart(): void {
    this.chart.destroy();
  }
}
