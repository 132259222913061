<form [formGroup]="formGroup">
  <p-selectButton
    formControlName="value"
    cssSelector=".p-button.p-component[role='radio']"
    optionDisabled="disabled"
    optionLabel="label"
    optionValue="value"
    sharedAriaLabelModifier
    [options]="toggleOptions()"
    (onChange)="selectedItemChanged.emit($event.value)">
  </p-selectButton>
</form>