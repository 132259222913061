<ng-container *transloco="let t">
  <p-dropdown
    #dropdown
    dataKey="id"
    [autoDisplayFirst]="false"
    [ngModel]="selected()"
    [options]="options()"
    [scrollHeight]="scrollHeight"
    (onChange)="onChange($event)">

    <ng-template pTemplate="selectedItem" let-item>
      @if (isRangeCustomLabelVisible()) {
        <span>{{ t('select.dateRange.rangeCustomLabel', { start: rangeCustomLabel().start, end: rangeCustomLabel().end }) }}</span>
      } @else {
        <span>{{ t(item.label) }}</span>
      }
    </ng-template>

    <ng-template pTemplate="item" let-item>
      <div
        class="option"
        tabindex="0"
        (click)="onClick($event, item); overlayDateRange.toggle($event)">
        <span>{{ t(item.label) }}</span>

        <p-overlayPanel
          #overlayDateRange
          styleClass="is-caret-hidden">
          <p-calendar
            selectionMode="range"
            [(ngModel)]="rangeCustom"
            [inline]="true"
            (click)="$event.stopPropagation()"
            (onSelect)="onSelect()">
          </p-calendar>
        </p-overlayPanel>

      </div>
    </ng-template>

  </p-dropdown>
</ng-container>