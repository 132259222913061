<div class="custom-split-btn"
  (click)="!disabled ? clickBtn.emit() : null"
  (keydown.enter)="!disabled ? clickBtn.emit() : null"
  (keydown.space)="!disabled ? clickBtn.emit() : null"
  tabindex="0"
  role="button">
  <p-button
    [label]="label"
    [attr.aria-label]="label"
    [icon]="leftIcon"
    [disabled]="disabled"
    class="custom-split-btn__btn" />
  <p-button
    [icon]="icon"
    [disabled]="disabled"
    [attr.aria-label]="icon"
    class="custom-split-btn__icon-btn">
    <span class="sr-only">{{ icon }}</span>
  </p-button>
</div>
